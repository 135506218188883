<template>
  <div>
    <!-- 头部 -->
    <div class="header">
      <div class="wrap">
        <div class="header-box">
          <div class="header-l"></div>
          <div class="header-r">
            <div @click="goLogin" v-if="loginFlag">登录</div>
            <div @click="goupload" v-else>进入管理页</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间 -->
    <div class="content">
      <div class="content-swiper">
        <!-- <div class="content-center">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide slide-box1"></div>
              <div class="swiper-slide slide-box2"></div>
              <div class="swiper-slide slide-box3"></div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>-->
      </div>
    </div>
    <div class="content2">
      <div class="wrap">
        <div style="text-align: right; margin: 24px 0">
          <!-- <input class="serachVal" type="text" v-model="value"> -->
          <!-- <select class="serachVal" v-model="value">
              <option lable="南部大区" value="南部大区"></option>
              <option lable="北部大区" value="北部大区"></option>
              <option lable="东部大区" value="北部大区"></option>
              <option lable="西部大区" value="西部大区"></option>
          </select>-->
          <!-- <span style="display: inline-block; margin-right: 40px">
            <span style="display: inline-block; margin-right: 20px">
              <input type="radio" name="南部大区" value="南部大区" /> 南部大区
            </span>
            <span style="display: inline-block; margin-right: 20px">
              <input type="radio" name="北部大区" value="北部大区" /> 北部大区
            </span>
            <span style="display: inline-block; margin-right: 20px">
              <input type="radio" name="东部大区" value="东部大区" /> 东部大区
            </span>
            <span style="display: inline-block; margin-right: 20px">
              <input type="radio" name="西部大区" value="西部大区" /> 西部大区
            </span>
          </span>-->
          <label v-for="(item, index) in options" :key="index">
            <input type="radio" :value="item.value" @change="getSelected" v-model="selected" />
            <span style="margin-right: 20px">{{ item.label }}</span>
          </label>
          <!-- </div> -->

          <span
            style="margin-right: 20px; cursor: pointer;"
            :class="index == 0 ? 'colorStyle': ''"
            @click="getMoney(0)"
          >价格从高到低</span>
          <span
            style="cursor: pointer;"
            :class="index == 1 ? 'colorStyle': ''"
            @click="getMoney(1)"
          >价格从低到高</span>
          <el-input class="serachVal" type="text" v-model="value" />
          <el-button class="serachBtn" type="primary" @click="search()">搜索</el-button>
        </div>
        <div class="list-container">
          <div
            class="list-container-item"
            v-for="(item, index) in dataList"
            :key="index"
            @click="godetails(item)"
          >
            <div style="position: relative; overflow: hidden">
              <div
                class="list-container-item-top"
                :style="{'backgroundImage': `url(${item.fileNameList? item.fileNameList[0] : ''})`}"
                style="
                  background-repeat: no-repeat;
                  background-size: cover;
                "
              >
                <!-- <img src="https://img.kuaishouyoo.com/UploadFiles/202310/22001139945805.png" alt=""> -->
              </div>
            </div>
            <div class="list-container-item-bottom">
              <div class="money">¥ {{ item.money }}</div>
              <div class="list-container-item-bottom-top">{{ item.remarks }}</div>
              <div class="list-container-item-bottom-center">
                <div>穿越火线</div>
                <div>【{{ item.area }}】</div>
              </div>
              <div class="list-container-item-bottom-bottom">
                <div class="tag1">永久包赔</div>
                <div class="tag2">永久包赔</div>
                <div class="tag3">永久包赔</div>
                <div class="tag4">永久包赔</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content2-bottom">
          <span v-if="loading" @click="getAddlist">查看更多帐号</span>
          <p v-else>暂无更多数据了。。。</p>
        </div>
      </div>
    </div>
    <div class="footer-box"> <span>备案号: </span><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023028743号-1</a ></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import axios from "axios";
export default {
  data() {
    return {
      value: "",
      loginFlag: true,
      selected: '',
      dataList: [],
      index: 1,
      pageSize: 12,
      pageNum: 1,
      loading: true,
      options: [
        { label: '南部大区', value: '南部' },
        { label: '北部大区', value: '北部' },
        { label: '东部大区', value: '东部' },
        { label: '西部大区', value: '西部' }
      ],
      lastTime: 0
    };
  },
  mounted() {
    this.getSwiper();
    // this.scroll();
    // 获取列表数据
    this.getList();
    if (sessionStorage.getItem("usernameWeb")) {
      this.loginFlag = false;
    } else {
      this.loginFlag = true;
    }
  },
  methods: {
    getSwiper() {
      var swiper = new Swiper(".mySwiper", {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    search() {
      this.dataList = [];
      console.log(this.value);
      this.pageNum = 1;
      this.getList();
    },
    goLogin() {
      this.$router.push({
        name: "login",
      });
    },
    goDetails(item) {
    //   console.log(item);
    //   this.$router.push({
    //     name: "details",
    //     query: {
    //       id: item.id
    //     }
    //   });
    },
    getList() {
      // this.loading = false;
      // /photosqueryFilterAllPhotos
      // if (!this.loading) {
      //   return;
      // }
      axios({
        method: "post",
        url: "https://www.cf666.top/admin/photos/queryFilterAllPhotos",
        data: {
          area: this.selected,
          searchName: this.value,
          money: this.index,
          pageSize: this.pageSize, // 每页的数据条数
          pageNum: this.pageNum, // 当前页码
        },
      })
        .then((result) => {
          // result不是直接的返回结果
          console.log("列表", result);
          this.dataList = [...this.dataList, ...result.data.payload.list];
          if (result.data.payload.list.length < 12) {
            this.loading = false
          }
          // if (result.data.success) {
          //  this.$router.push({
          //     name:'home'
          //  })
          // }
          // sessionStorage.setItem("usernameWeb", this.username);
          // result.data才是真正的返回结果
          // console.log("真正的数据：", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    godetails(item) {
      console.log(item);
      this.$router.push({
        name: "details",
        query: {
          id: item.id
        }
      });
    },
    getSelected() {
      this.dataList = [];
      this.pageNum = 1;
      // console.log(this.selected);
      this.getList();
    },
    getMoney(item) {
      this.dataList = [];
      this.pageNum = 1;
      this.index = item;
      this.getList()
    },
    goupload() {
      // sessionStorage.removeItem('usernameWeb');
      this.$router.push({
        name: 'uploadData'
      })
      // this.
    },
    scroll() {
      window.onscroll = () => {
        // console.log(123);
        let docEl = document.documentElement;
        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let docELHeight = docEl.scrollHeight;
        let scrollTop = docEl.scrollTop;
        console.log(Math.floor(scrollTop), docELHeight, clientHeight);
        
        if (Math.floor(scrollTop) >= docELHeight - clientHeight) {
          // console.log('daodi');
          let currentTime = Date.now();
          if (currentTime - this.lastTime >= 3000) {
            // console.log('jieliu');
            this.pageNum = this.pageNum + 1;
            this.lastTime = currentTime;
                  axios({
        method: "post",
        url: "https://www.cf666.top/admin/photos/queryFilterAllPhotos",
        data: {
          area: this.selected,
          searchName: this.value,
          money: this.index,
          pageSize: this.pageSize, // 每页的数据条数
          pageNum: this.pageNum, // 当前页码
        },
      })
        .then((result) => {
          // result不是直接的返回结果
          console.log("列表", result);
          this.dataList = [...this.dataList,...result.data.payload.list];
          // if (result.data.success) {
          //  this.$router.push({
          //     name:'home'
          //  })
          // }
          // sessionStorage.setItem("usernameWeb", this.username);
          // result.data才是真正的返回结果
          // console.log("真正的数据：", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
            
          }
          
        }
      // axios({
      //   method: "post",
      //   url: "https://www.cf666.top/admin/photos/queryFilterAllPhotos",
      //   data: {
      //     area: this.selected,
      //     searchName: this.value,
      //     money: this.index,
      //     pageSize: this.pageSize, // 每页的数据条数
      //     pageNum: this.pageNum, // 当前页码
      //   },
      // })
      //   .then((result) => {
      //     this.loading = false;
      //     // result不是直接的返回结果
      //     console.log("列表", result);
      //     this.dataList = [...this.dataList,...result.data.payload.list];
      //     // if (result.data.success) {
      //     //  this.$router.push({
      //     //     name:'home'
      //     //  })
      //     // }
      //     // sessionStorage.setItem("usernameWeb", this.username);
      //     // result.data才是真正的返回结果
      //     // console.log("真正的数据：", result.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
           
        //  }
      }
    },
    getAddlist() {
      this.pageNum = this.pageNum + 1;
      this.getList();
    }
  },
};
</script>

<style lang='less' scoped>
// 头部
.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #f8f8f8;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.header-box {
  display: flex;
  justify-content: space-between;
}
.header-r {
  display: flex;
  div {
    margin-right: 20px;
    cursor: pointer;
  }
}
// 中间
.content {
  height: 805px;
  position: relative;
  background-image: url("./img/home.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  .content-swiper {
    position: absolute;
    width: 100%;
    height: 300px;
    bottom: 60px;
    left: 0;
    .content-center {
      width: 700px;
      height: 100%;
      border: 1px solid #ff6700;
      margin: 0 auto;
      //   position: absolute;

      //   轮播
      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .slide-box1 {
        background-image: url("./img/1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .slide-box2 {
        background-image: url("./img/2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .slide-box3 {
        background-image: url("./img/3.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .swiper-container {
        height: 100%;
      }
    }
  }
}
.content2 {
  background: #e6ecea;
  padding: 30px 0;
  .list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    // border: 1px solid #ff6700;
    grid-gap: 20px 20px;
    .list-container-item {
      cursor: pointer;
      overflow: hidden;
      // position: relative;
      // box-shadow: 5px 5px 1px 1px black;
      .list-container-item-top {
        width: 100%;
        height: 200px;
        // overflow: hidden;
        // position: relative;
        transition: transform 0.8s;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .list-container-item-bottom {
        padding: 10px;
        background: #fff;
        position: relative;
        .list-container-item-bottom-top {
          height: 60px;
          line-height: 20px;
          overflow: hidden; //隐藏溢出
          text-overflow: ellipsis; //省略号
          display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
          -webkit-line-clamp: 3; //限制行数
          -webkit-box-orient: vertical; //垂直方向排列
          font-size: 12px;
          color: #000;
          font-family: PingFangSC-Semibold, sans-serif;
        }
        .list-container-item-bottom-center {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          margin: 5px 0;
        }
        .list-container-item-bottom-bottom {
          display: flex;
          justify-content: space-between;
          div {
            border-radius: 4px;
            font-size: 12px;
            width: 20%;
            text-align: center;
          }
          .tag1 {
            border: 1px solid #e1f3d8;
            background: #f0f9eb;
            color: #67c23a;
          }
          .tag2 {
            border: 1px solid #d9ecff;
            background: #ecf5ff;
            color: #409eff;
          }
          .tag3 {
            border: 1px solid #faecd8;
            background: #fdf6ec;
            color: #e6a23c;
          }
          .tag4 {
            border: 1px solid #fde2e2;
            background: #fef0f0;
            color: #f56c6c;
          }
        }
        .money {
          width: 100px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-weight: 600;
          background: #ff6700;
          position: absolute;
          top: -30px;
          right: 0;
        }
      }
      // background: red;
    }
    .list-container-item:hover {
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      transform: translate3d(0, -2px, 0);
      transition: all 0.2s linear;
    }

    .list-container-item:hover .list-container-item-top {
      transform: scale(1.2);
    }
    .list-container-item:hover
      .list-container-item-bottom
      .list-container-item-bottom-top {
      color: red;
    }
  }
  .content2-bottom {
    margin: 20px auto;
    text-align: center;
    span {
      display: inline-block;
      height: 60px;
      line-height: 60px;
      padding: 0 29px;
      cursor: pointer;
      background: #fff;
    }
    span:hover {
      background: #ff6700;
      color: #fff;
    }
  }
  .serachVal {
    width: 200px;
    height: 30px;
    outline: none;
    margin-left: 20px;
  }
  // .serachBtn {
    // width: 70px;
    // height: 30px;
    // background: #409eff;
    // border: unset;
    // cursor: pointer;
    // color: #fff;
    // margin-left: 20px;
    // border-radius: 4px;
  // }
  .serachBtn:hover {
    background: #66b1ff;
  }
}
.footer-box {
    text-align: center;
    background: #001529;
    padding: 20px 0;
    font-size: 16px;
    // position: fixed;
    // bottom: 0;
    color: #fff;
    width: 100%;
    
    z-index: 99;
    a {
      color: #fff;
      text-decoration: none;
    }
}
.colorStyle {
  color: #409eff;
}
</style>