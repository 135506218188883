import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import pcIndex from "../views/pc";
import phoneIndex from "../views/phone";
import login from "../views/pc/login.vue";
import details from "../views/pc/details.vue";
import phoneDetails from "../views/phone/phoneDetails.vue";
import uploadData from "../views/pc/uploadData.vue";

Vue.use(VueRouter);

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

const routes = [
  {
    path: "/pcIndex",
    name: "home",
    component: pcIndex,
  },
  {
    path: "/details",
    name: "details",
    component: details,
  },
  {
    path: "/uploadData",
    name: "uploadData",
    component: uploadData,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },

  {
    path: "/phoneIndex",
    name: "phoneIndex",
    component: phoneIndex,
  },
  {
    path: "/phoneDetails",
    name: "phoneDetails",
    component: phoneDetails,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
// router.beforeEach((to, from, next) => {
//   const isMobile = window.innerWidth < 768 // 设置移动设备的屏幕分辨率阈值
//   if (to.matched.some(record => record.meta.requiresMobile) && !isMobile) {
//     next('/login') // 如果路由需要移动设备而当前是桌面设备，则跳转到桌面路由
//   } else if (to.matched.some(record => record.meta.requiresDesktop) && isMobile) {
//     next('/slogin') // 如果路由需要桌面设备而当前是移动设备，则跳转到移动路由
//   } else {
//     next() // 否则继续正常导航
//   }
// })
