<template>
  <div class="weiming">
    <div class="loginBox">
      <p class="title">login</p>
      <!-- <form method="post" id="loginForm" onsubmit="return checkForm()"> -->
      <div class="login_input">
        <div class="item">
          <p style="color: #fff">账号:</p>
          <input type="username" v-model="username" />
          <!-- <label>账号</label> -->
        </div>
        <div class="item">
          <p style="color: #fff">密码:</p>
          <input type="password" v-model="password" />
          <!-- <label>密码</label> -->
        </div>
      </div>
      <!-- <div class="form-group">
          <div class="col-xs-12">
            <div class="checkbox checkbox-danger">
              <input id="checkbox-signup" name="remember" type="checkbox" />
              <label for="checkbox-signup">记住我</label>
            </div>
          </div>
        </div> -->
      <div class="btn_box">
        <button class="btn" id="btn-login" @click="loginclick">登录</button>
      </div>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    loginclick() {
      console.log(this.password, this.username);
      axios({
        method: "post",
        url: "https://www.cf666.top/admin/admin/login",
        data:{  //后端使用@requestparam接收
          username: this.username,
          password:this.password,
        },

      }).then((result) => {
        // result不是直接的返回结果
        // console.log("数据：", result);
        if (result.data.success) {
         this.$router.push({
            name:'uploadData'
         })
        }
        sessionStorage.setItem('usernameWeb', this.username)
        // result.data才是真正的返回结果
        // console.log("真正的数据：", result.data);
      }).catch((err)=>{
        console.log(err);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.weiming {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("https://game.gtimg.cn/images/cf/cp/a20231011novweek1/pc/banner.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
// a {
//   text-decoration: none;
// }

// input,
// button {
//   background: transparent;
//   border: 0;
//   outline: none;
// }

// body {
//   height: 100vh;
//   background: linear-gradient(#141e30, #243b55);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 16px;
//   color: #03e9f4;
// }

.loginBox {
  width: 400px;
  height: 364px;
  background-color: #0c1622;
  opacity: 0.8;
  //   margin: auto;
  //   margin: 100px auto;
  border-radius: 10px;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
  //   padding: 40px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    height: 30px;
    line-height: 30px;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    margin: 20px 0;
    color: #fff;
  }
  .login_input {
    width: 80%;
    margin: 0 auto;
  }
  .btn_box {
    text-align: center;
  }
}

// h2 {
//   text-align: center;
//   color: aliceblue;
//   margin-bottom: 30px;
//   font-family: "Courier New", Courier, monospace;
// }

.item {
  //   height: 45px;
  //   border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  position: relative;
  //   width: 80%;
  //   margin: 0 auto;
}

.item input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  //   height: 100%;
  color: #000;
  //   padding-top: 20px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
}
.item input:focus {
  outline: none;
}

.item input:focus + label,
.item input:valid + label {
  top: 0px;
  font-size: 2px;
}

.item label {
  position: absolute;
  left: 0;
  top: 12px;
  transition: all 0.5s linear;
}

.btn {
  padding: 10px 20px;
  //   margin-top: 30px;
  color: #03e9f4;
  //   position: relative;
  //   overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  //   left: 35%;
}

.btn:hover {
  border-radius: 5px;
  color: #fff;
  background: #03e9f4;
  box-shadow: 0 0 5px 0 #03e9f4, 0 0 25px 0 #03e9f4, 0 0 50px 0 #03e9f4,
    0 0 100px 0 #03e9f4;
  transition: all 1s linear;
}

.btn > span {
  position: absolute;
}

.btn > span:nth-child(1) {
  width: 100%;
  height: 2px;
  background: -webkit-linear-gradient(left, transparent, #03e9f4);
  left: -100%;
  top: 0px;
  animation: line1 1s linear infinite;
}

// @keyframes line1 {
//   50%,
//   100% {
//     left: 100%;
//   }
// }

// .btn > span:nth-child(2) {
//   width: 2px;
//   height: 100%;
//   background: -webkit-linear-gradient(top, transparent, #03e9f4);
//   right: 0px;
//   top: -100%;
//   animation: line2 1s 0.25s linear infinite;
// }

// @keyframes line2 {
//   50%,
//   100% {
//     top: 100%;
//   }
// }

// .btn > span:nth-child(3) {
//   width: 100%;
//   height: 2px;
//   background: -webkit-linear-gradient(left, #03e9f4, transparent);
//   left: 100%;
//   bottom: 0px;
//   animation: line3 1s 0.75s linear infinite;
// }

// @keyframes line3 {
//   50%,
//   100% {
//     left: -100%;
//   }
// }

// .btn > span:nth-child(4) {
//   width: 2px;
//   height: 100%;
//   background: -webkit-linear-gradient(top, transparent, #03e9f4);
//   left: 0px;
//   top: 100%;
//   animation: line4 1s 1s linear infinite;
// }

// @keyframes line4 {
//   50%,
//   100% {
//     top: -100%;
//   }
// }
</style>