<template>
  <div>
    <!-- 头部 -->
    <div class="header">
      <div class="header-l"></div>
      <div class="header-r"></div>
    </div>
    <!-- 轮播 -->
    <div class="swiper-box">
      <div class="content-swiper">
        <div class="content-center">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slidebox1" style="backgroundImage: url('./img/1.jpg');background-repeat: no-repeat;
                  background-size: cover;">
                <img src="./img/1.jpg" alt />
              </div>
              <div class="swiper-slide swiper-slidebox2" style="backgroundImage: url('./img/1.jpg');background-repeat: no-repeat;
                  background-size: cover;">
                <img src="./img/2.jpg" alt />
              </div>
            </div>
            <div class="swiper-pagination" slot="pagination"></div>

            <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>-->
          </div>
        </div>
      </div>
    </div>
    <!-- 区域 -->
    <div class="quyu">
      <div
        v-for="(item, index) in options"
        :key="index"
        @click="getQuyu(item)"
        :class="indexs == item.value ? 'quyucolor' : ''"
      >{{ item.label }}</div>
    </div>
    <div class="money_box">
      <div :class="money == 0 ? 'colorStyle': ''" @click="getMoney(0)">↓价格从高到低</div>
      <div :class="money == 1 ? 'colorStyle': ''" @click="getMoney(1)">↑价格从低到高</div>
    </div>
    <div style="display: flex;justify-content: space-between;">
        <el-input  class="serachVal" type="text" v-model="value" />
          <el-button class="serachBtn" type="primary" @click="search()">搜索</el-button>
    </div>
    <div class="list">
      <p class="list-title">最新帐号</p>
      <div class="list-box" v-for="(item, index) in dataList" :key="index" @click="goDetails(item)">
        <div class="list-box-l">
          <div :style="{ backgroundImage: `url(${item.fileNameList? item.fileNameList[0] : ''})` }">
            <div class="money">¥ {{ item.money }}</div>
          </div>
        </div>
        <div class="list-box-r">
          <div class="list-box-r-top">
            {{ item.remarks }}
            <!-- D86新到北部【北京一区】紫金会员6可排位【可更改二次实名】【幻神/竞技荣光M200】 -->
          </div>
          <div class="list-box-r-center">
            <div>穿越火线</div>
            <div>【{{ item.area }}】</div>
          </div>
          <div class="list-box-r-bottom">
            <div class="tag1">永久包赔</div>
            <div class="tag2">永久包赔</div>
            <div class="tag3">永久包赔</div>
            <div class="tag4">永久包赔</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2-bottom">
      <span v-if="loading" @click="getAddlist">查看更多帐号</span>
      <p v-else>暂无更多数据了。。。</p>
    </div>
    <div class="footer-box"> <span>备案号: </span><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023028743号-1</a ></div>
  </div>
</template>

<script scoped src="./rem.js"></script>
<script>
import Swiper from "swiper";
import axios from "axios";
export default {
  data() {
    return {
      value: "",
      loginFlag: true,
      selected: "",
      dataList: [],
      indexs: "",
      money: 1,
      pageSize: 12,
      pageNum: 1,
      loading: true,
      options: [
        { label: "南部大区", value: "南部", id: 1 },
        { label: "北部大区", value: "北部", id: 2 },
        { label: "东部大区", value: "东部", id: 3 },
        { label: "西部大区", value: "西部", id: 4 },
      ],
    };
  },
  mounted() {
    this.getSwiper();
    this.getList();
  },
  methods: {
    getSwiper() {
      var swiper = new Swiper(".mySwiper", {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
      });
    },
    goDetails(item) {
      this.$router.push({
        name: "phoneDetails",
        query: {
          id: item.id
        }
      });
    },
    getList() {
      // /photosqueryFilterAllPhotos
      axios({
        method: "post",
        url: "https://www.cf666.top/admin/photos/queryFilterAllPhotos",
        data: {
          area: this.indexs,
          searchName: this.value,
          money: this.money,
          pageSize: this.pageSize, // 每页的数据条数
          pageNum: this.pageNum, // 当前页码
          //后端使用@requestparam接收
          // username: this.username,
          // password:this.password,
        },
      })
        .then((result) => {
          // result不是直接的返回结果
          console.log("列表", result);
          // this.dataList = result.data.payload.list;
          this.dataList = [...this.dataList, ...result.data.payload.list];
          if (result.data.payload.list.length < 12) {
            this.loading = false
          }
          // if (result.data.success) {
          //  this.$router.push({
          //     name:'home'
          //  })
          // }
          // sessionStorage.setItem("usernameWeb", this.username);
          // result.data才是真正的返回结果
          // console.log("真正的数据：", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuyu(item) {
      this.dataList = [];
      this.pageNum = 1;
      this.indexs = item.value;
      // console.log(this.indexs, item.id);
      this.getList();
    },
    getMoney(item) {
      this.dataList = [];
      this.money = item;
      this.pageNum = 1;
      this.getList();
    },
    getAddlist() {
      this.pageNum = this.pageNum + 1;
      this.getList();
    },
    search() {
      this.dataList = [];
      console.log(this.value);
      this.pageNum = 1;
      this.getList();
    },
  },
};
</script>

<style lang='less' scoped>
.header {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  padding: 0 0.2rem;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .header-l {
    font-size: 1rem;
  }
  .header-r {
    font-size: 1rem;
  }
}
.swiper-box {
  width: 100%;
  height: 11rem;
  margin-bottom: 0.8rem;
  img {
    width: 100%;
    // height: 11rem;
  }
}
.quyu {
  padding: 0.8rem;
  height: 4.56rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0px 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  div {
    line-height: 2.96rem;
    background-image: url("http://hswyo.cn/images/index-m2.png");
    background-size: cover;
    text-align: center;
    color: #fff;
    cursor: pointer;
    // color: #ff6700;
  }
  .quyucolor {
    color: #ff6700;
  }
}
.money_box {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin: 1rem 0;
  .colorStyle {
    color: #ff6700;
  }
}
.list {
  padding-top: 1.2rem;

  .list-title {
    padding-left: 1rem;
    font-size: 1.2rem;
    font-weight: 900;
  }
  .list-box {
    padding: 0.62rem 1rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    height: 6.25rem;
    .list-box-l {
      flex: 3;
      height: 100%;
      div {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        .money {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1.2rem;
          width: 100%;
          background: #ff6700;
          text-align: center;
          color: #fff;
        }
      }
    }
    .list-box-r {
      flex: 7;
      padding-left: 0.5rem;
      .list-box-r-top {
        overflow: hidden; //隐藏溢出
        text-overflow: ellipsis; //省略号
        display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
        -webkit-line-clamp: 2; //限制行数
        -webkit-box-orient: vertical; //垂直方向排列
        font-size: 1rem;
        font-weight: 500;
      }
      .list-box-r-center {
        display: flex;
        justify-content: space-between;
        margin-top: 0.2rem;
      }
      .list-box-r-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 0.4rem;
        div {
          border-radius: 0.2rem;
          font-size: 0.6rem;
          width: 20%;
          text-align: center;
        }
        .tag1 {
          border: 1px solid #e1f3d8;
          background: #f0f9eb;
          color: #67c23a;
        }
        .tag2 {
          border: 1px solid #d9ecff;
          background: #ecf5ff;
          color: #409eff;
        }
        .tag3 {
          border: 1px solid #faecd8;
          background: #fdf6ec;
          color: #e6a23c;
        }
        .tag4 {
          border: 1px solid #fde2e2;
          background: #fef0f0;
          color: #f56c6c;
        }
      }
    }
  }
}
.content2-bottom {
    margin: 20px auto;
    text-align: center;
    span {
      display: inline-block;
      height: 60px;
      line-height: 60px;
      padding: 0 29px;
      cursor: pointer;
      background: #ff6700;
      color: #fff
    }
    // span:hover {
    //   background: #ff6700;
    //   color: #fff;
    // }
  }
  // .content-swiper {
  //   width: 100%;
  //   height: 100%;
  // }
  // .content-center {
  //   width: 100%;
  //   height: 100%;
  // }
  // .swiper-container {
  //       width: 100%;
  //   height: 100%;
  // }
  .footer-box {
    text-align: center;
    background: #001529;
    padding: 20px 0;
    font-size: 16px;
    // position: fixed;
    // bottom: 0;
    color: #fff;
    width: 100%;
    
    z-index: 99;
    a {
      color: #fff;
      text-decoration: none;
    }
}
.swiper-slide{
  img{
    width: 100%;
    height: 11rem;
  }
}
</style>