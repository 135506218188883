<template>
  <div class="details-page">
    <div class="wrap">
      <div style="display: flex; justify-content: space-between;">
      <h3 style="padding: 20px 0;cursor: pointer;" @click="gopath"><返回上一页</h3>
      <h3 style="padding: 20px 0">帐号详情</h3>
      </div>
      <div class="datails-box">
        <div class="datails-box-left">
          <div class="content-center">
            <div class="swiper-container mySwiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in payload.fileNameList"
                  :key="index"
                  :style="{ backgroundImage: `url(${item})` }"
                  style="
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                "
                ></div>
              </div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
        <div class="datails-box-right">
          <div class="datails-box-right-top">
            {{ payload.remarks }}
          </div>
          <div class="datails-box-right-center">
            <div>价格</div>
            <div class="money">¥{{ payload.money }}</div>
            <div class="tag">
              <span>官方自营</span>
            </div>
          </div>
          <div class="datails-box-right-bottom">
            <div>
              <span class="label">商品类型：</span>
              <span class="value">游戏账号</span>
            </div>
            <div>
              <span class="label">商品编号：</span>
              <span class="value">游戏账号</span>
            </div>
            <div>
              <span class="label">游戏区服：</span>
              <span class="value">游戏账号</span>
            </div>
            <div>
              <span class="label">交易时间：</span>
              <span class="value">全天</span>
            </div>
          </div>
          <div class="datails-box-right-button">
            <span @click="dialogFn">截图联系客服</span>
          </div>
        </div>
      </div>
      <div>
        <div
          v-for="(item, index) in taglist"
          :key="index"
          @click="setTab(item)"
          class="tabstyle"
          :class="item.value == active ? 'active' : ''"
        >
          <span>{{ item.label }}</span>
        </div>
        <div v-if="active == 1" class="taglist-box">
          <!-- <div class="taglist-box-top" style="padding: 30px">
            <p>商品编号：</p>
            <p>商品编号：</p>
            <p>商品编号：</p>
            <p>商品编号：</p>
            <p>商品编号：</p>
            <p>商品描述：</p>
            <p>
              商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述商品描述
            </p>
          </div> -->

          <!-- <div class="details-title">商品详情图片</div> -->
          <div class="img-box">
            <div v-for="(item,index) in payload.fileNameList" :key="index">
              <img :src="item" alt="">
            </div>
            <img src="http://hswyo.cn/images/liucheng.png" alt="" />
            <!-- <img
              src="https://img.kuaishouyoo.com/UploadFiles/202311/11094923000805.png"
              alt=""
            />
            <div>
              <img
                src="https://img.kuaishouyoo.com/UploadFiles/202311/11094923000805.png"
                alt=""
              />
            </div> -->
          </div>
        </div>
        <!-- <div v-if="active == 2" class="taglist-box">
          <img src="http://hswyo.cn/images/liucheng.png" alt="" />
        </div> -->
      </div>
    </div>
    <div v-if="dialogFlag" class="dialog">
      <div class="dialog-box">
        <div class="dialog-box-content">
          <img src="../phone//img/wx.jpg" alt="">
        </div>
        <div class="queding" @click="dialogFn">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import axios from "axios";
export default {
  name: "details",
  data() {
    return {
      dialogFlag: false,
      taglist: [
        { label: "商品详情", value: 1 },
        // { label: "购买流程", value: 2 },
      ],
      active: 1,
      payload: {},
    };
  },
  mounted() {
    this.$nextTick(()=>{
      setTimeout(() => {
        this.getSwiper();
      }, 1000);
      
    })
    this.getList();
  },
  methods: {
    getSwiper() {
      var swiper = new Swiper(".mySwiper", {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    dialogFn() {
      this.dialogFlag = !this.dialogFlag;
    },
    setTab(item) {
      this.active = item.value;
    },
    getList() {
      // /photosqueryFilterAllPhotos
      axios({
        method: "get",
        url: `https://www.cf666.top/admin/photos/queryPhotosInfoDetails/${this.$route.query.id}`,
        params: {
          // id: this.$route.query.id
        },
      })
        .then((result) => {
          console.log(result);
          this.payload = result.data.payload;

          // result不是直接的返回结果
          // console.log("列表", result);
          // this.dataList = result.data.payload;
          // if (result.data.success) {
          //  this.$router.push({
          //     name:'home'
          //  })
          // }
          // result.data才是真正的返回结果
          // console.log("真正的数据：", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gopath() {
      this.$router.push({
        name:'home'
      })
    }
  },
};
</script>

<style lang='less' scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.details-page {
  background: #e5ebe9;
}
.datails-box {
  background: #fff;
  padding: 30px;
  display: flex;
  // justify-content: space-between;
  .datails-box-left {
    margin-right: 30px;
  }
  .datails-box-right {
    flex: 1;
  }
  .datails-box-right-top {
    height: 80px;
    line-height: 20px;
    overflow: hidden; //隐藏溢出
    text-overflow: ellipsis; //省略号
    display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
    -webkit-line-clamp: 4; //限制行数
    -webkit-box-orient: vertical; //垂直方向排列
    font-size: 14px;
    color: #000;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  .datails-box-right-center {
    height: 60px;
    line-height: 60px;
    background: #e5ebe9;
    padding: 0 30px;
    margin: 30px 0;
    display: flex;
    .money {
      color: #ff0026;
      font-size: 24px;
      margin: 0 20px;
    }
    .tag {
      span {
        padding: 5px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        background: #ff0026;
        border-radius: 4px;
        margin: auto;
      }
    }
  }
  .datails-box-right-bottom {
    div {
      margin: 15px;
      font-size: 16px;
      .label {
        margin-right: 15px;
        color: #7f7f7f;
      }
      .value {
        color: #000;
      }
    }
  }
  .datails-box-right-button {
    height: 30px;
    text-align: center;
    span {
      display: inline-block;
      padding: 10px;
      background: #ff0026;
      color: #fff;
      cursor: pointer;
    }
    span:hover {
      background: #ed5a70;
    }
  }
}

.content-center {
  width: 500px;
  height: 400px;
  border: 1px solid #ff6700;
  margin: 0 auto;
  //   position: absolute;

  //   轮播
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-container {
    height: 100%;
  }
}

.dialog {
  width: 100%;
  height: 100vh;
  position: fixed;
  // position: relative;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
  .dialog-box {
    position: absolute;
    width: 500px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    .dialog-box-content {
      height: 360px;
      line-height: 330px;
      text-align: center;
      border-bottom: 1px solid #7f7f7f;
      img {
        width: 100%;
        height: 360px;
      }
    }
    .queding {
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #40affe;
      font-size: 14px;
    }
  }
}
.tabstyle {
  display: inline-block;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  // box-sizing: border-box;
  border-top: 3px solid #e5ebe9;
  margin-top: 20px;
}
.active {
  border-top: 3px solid #ff0026;
  background: #fff;
}
.taglist-box {
  background: #fff;
  // padding: 30px;
  p {
    color: #7f7f7f;
    margin-bottom: 20px;
  }
  .details-title {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    background: #e5ebe9;
    color: #000;
    padding-left: 20px;
  }
  .img-box {
    img {
      width: 100%;
      
    }
  }
}
</style>