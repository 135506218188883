<template>
  <div class="upload-box">
    <!-- <div>
      <div
        v-for="(item, index) in dataList"
        :key="index"
        class="upload-box-item"
      >
        <div class="upload-box-item-top">
          <span>编号：{{ item.number }}</span>
          <span>大区：{{ item.area }}</span>
          <span>金额：{{ item.money }}</span>
        </div>
        <div class="upload-box-item-bottom">描述：{{ item.remarks }}</div>
        <p class="upload-box-item-delete">
          <span class="delete">删除</span>
        </p>
      </div>
    </div>
    <div>
      <div class="upload-edit">

        <el-form ref="form" :model="form">
          <el-form-item label="活动名称">
            <el-input v-model="form.daqu"></el-input>
          </el-form-item>
          <el-form-item label="活动名称">
            <el-input v-model="form.jine"></el-input>
          </el-form-item>
          <el-form-item label="活动名称">
            <el-input type="textarea" v-model="form.miaoshu"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div></div>
    </div> -->
    <div class="uploadbox">
      <el-button type="primary" @click="goindex">进入首页</el-button>
      <el-button type="primary" @click="logOut">退出登录</el-button>
      <el-button type="primary" @click="uploadDialog">上传数据</el-button>
    </div>
    <div style="margin-bottom: 30px">
      <el-table border :data="dataList" style="width: 100%">
        <el-table-column prop="number" label="编号"> </el-table-column>
        <el-table-column prop="area" label="大区"> </el-table-column>
        <el-table-column prop="money" label="金额"> </el-table-column>
        <el-table-column prop="remarks" label="描述"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox" style="text-align: right;padding-top: 30px;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :current-page="currentPage"
          :total="total"
          :page-size="pageSize"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="上传" :visible.sync="dialogVisible" width="55%">
      <!-- <span>这是一段信息</span> -->
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item label="大区" prop="daqu">
          <el-input v-model="form.daqu"></el-input>
        </el-form-item>
        <el-form-item label="金额" prop="jine">
          <el-input v-model="form.jine"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="miaoshu">
          <el-input type="textarea" v-model="form.miaoshu"></el-input>
        </el-form-item>
        <el-form-item label="上传">
          <el-upload
          :show-file-list="false"
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :http-request="uploadFile"
            :on-exceed="handleExceed"
            :file-list="form.files"
            :on-change="changeFileLength"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
          </el-upload>
          <p v-if="form.files.length">共上传了{{ form.files.length }} 个图片</p>
          <!-- <el-input type="textarea" v-model="form.files"></el-input> -->
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "uploadData",
  data() {
    return {
      dataList: [],
      daqu: "",
      jine: "",
      miaoshu: "",
      filelist: [],
      form: {
        daqu: "",
        jine: "",
        miaoshu: "",
        files: [],
      },
      dialogVisible: false,
      rules: {
        daqu: [{ required: true, message: "请输入大区", trigger: "blur" }],
        jine: [{ required: true, message: "请输入金额", trigger: "blur" }],
        miaoshu: [{ required: true, message: "请输入描述", trigger: "blur" }],
      },
      currentPage: 1, // 当前页码
      total: 0, // 总条数
      pageSize: 10, // 每页的数据条数
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$refs.form.resetFields();
        this.form.files = [];
      }

    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios({
        method: "post",
        url: "https://www.cf666.top/admin/photos/queryAllPhotosList",
        data: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        console.log(res);
        this.dataList = res.data.payload.list;
        this.total = res.data.payload.total;
        this.currentPage = res.data.payload.pageNum;
        this.pageSize = res.data.payload.pageSize;

      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },

    uploadDialog() {
      this.dialogVisible = true;
    },
    handleRemove(file, filelist) {
      this.form.files = filelist;
      //   this.filelist = filelist;
    },
    handlePreview() {},
    beforeRemove() {},
    handleExceed() {},
    uploadFile(file, filelist) {},
    changeFileLength(file, filelist) {
      this.form.files = filelist;
      //   this.filelist = filelist;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("area", this.form.daqu);
          formData.append("remarks", this.form.miaoshu);
          formData.append("money", this.form.jine);
          //   formData.append("files", this.form.files);
          this.form.files.forEach((file) => {
            formData.append("files", file.raw);
          });

          axios({
            method: "post",
            url: "https://www.cf666.top/admin/photos/uploadPhotos",
            headers: {
              "Content-Type": "multipart/form-data", // 设置请求头，告诉服务器发送的是 FormData 数据
            },
            data: formData,
          })
            .then((res) => {
              //   console.log(res);
              this.$refs.form.resetFields();
              this.form.files = [];
              this.dialogVisible = false;
              this.getList();
              // this.dataList = res.data.payload.list;
            })
            .catch((err) => {
              alert("上传失败！");
            });
          // console.log('进来');
        }
      });
    },
    handleClick(row) {
      // console.log(row);
      axios({
        method: "get",
        url: `https://www.cf666.top/admin/photos/deletePhotos/${row.id}`,
      }).then((res) => {
        // console.log(res);
        this.getList();

        // this.dataList = res.data.payload.list;
      });
    },
    logOut() {
      sessionStorage.removeItem("usernameWeb");
      this.$router.push({
        name: "login",
      });
    },
    goindex() {
      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>

<style lang='less' scoped>
.upload-box {
  width: 1200px;
  margin: 30px auto;
  border: 1px solid #e0e0e0;
  .uploadbox {
    width: 100%;
    text-align: right;
    padding: 30px;
    box-sizing: border-box;
  }
  //   display: flex;
  //   div {
  //     width: 50%;
  //   }
  //   .upload-box-item {
  //     width: 100%;
  //     padding: 20px;
  //     border: 1px solid #ff6700;
  //     box-sizing: border-box;
  //     .upload-box-item-top {
  //       width: 100%;
  //       span {
  //         margin-right: 10px;
  //       }
  //     }
  //     .upload-box-item-bottom {
  //       width: 100%;
  //       margin-top: 10px;
  //     }
  //     .upload-box-item-delete {
  //       width: 100%;
  //       text-align: right;
  //       .delete {
  //         margin-right: 10px;
  //       }
  //       .delete:hover {
  //         cursor: pointer;
  //         color: red;
  //       }
  //     }
  //   }
  //   .upload-edit {
  //     width: 100%;
  //     div {
  //       width: 100%;
  //       //   padding: 20px;
  //       //   box-sizing: border-box;
  //       /deep/.el-form {
  //         .el-form-item {
  //           margin-bottom: 0px;
  //         }
  //       }
  //     }
  //   }
}
</style>