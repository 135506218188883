<template>
  <div>
    <!-- 详情 -->
    <p style="height: height: 2rem;line-height: 2rem" @click="gopath"><返回上一页</p>
    <div class="swiper-box">
      <div class="content-swiper">
        <div class="content-center">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img
                  src="http://hswyo.cn/UploadFiles/202308/1617231760719.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="http://hswyo.cn/UploadFiles/202308/1617245567745.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="http://hswyo.cn/UploadFiles/202308/1617231760719.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="swiper-pagination" slot="pagination"></div>

            <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="datails-center">
      <div class="datails-center-top">
        {{ payload.remarks }}
      </div>
      <div class="datails-center-center">
        <div style="margin-bottom: 1rem">
          <span>价格</span>
          <span class="money">¥{{ payload.money }}</span>
        </div>
        <div class="tag">
          <span> 官方自营 </span>
        </div>
      </div>
      <div class="datails-center-button" @click="dialogFn">截图联系客服</div>
      <div class="datails-center-bottom">
        <p></p>
      </div>
    </div>
    <div>
      <div
        v-for="(item, index) in taglist"
        :key="index"
        @click="setTab(item)"
        class="tabstyle"
        :class="item.value == active ? 'active' : ''"
      >
        <span>{{ item.label }}</span>
      </div>
      <div v-if="active == 1" class="taglist-box">
        <div class="taglist-box-top" style="padding: 30px">
          <p>商品编号：{{ payload.number }}</p>
          <p>商品描述：</p>
          <p>
            {{ payload.remarks }}
          </p>
        </div>

        <div class="details-title">商品详情图片</div>
        <div class="img-box">
        <div v-for="(item,index) in payload.fileNameList" :key="index">
              <img :src="item" alt="">
            </div>
        </div>
      </div>
      <div v-if="active == 2" class="taglist-box">
        <img style="width: 100%" src="http://hswyo.cn/images/liucheng.png" alt="" />
      </div>
    </div>

    <!-- 弹窗 -->
    <div v-if="dialogFlag" class="dialog">
      <div class="dialog-box">
        <div class="dialog-box-content">
          <img src="../phone//img/wx.jpg" alt="">
        </div>
        <div class="queding" @click="dialogFn">确定</div>
      </div>
    </div>
  </div>
</template>
<script scoped src="./rem.js"></script>
<script>
import Swiper from "swiper";
import axios from "axios";
export default {
  name: "phoneDetails",
  data() {
    return {
      dialogFlag: false,
      taglist: [
        { label: "商品详情", value: 1 },
        { label: "购买流程", value: 2 },
      ],
      active: 1,
      payload: {},
    };
  },
  mounted() {
    this.getSwiper();
    this.getList();
  },
  methods: {
    getSwiper() {
      var swiper = new Swiper(".mySwiper", {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
      });
    },
    dialogFn() {
      this.dialogFlag = !this.dialogFlag;
    },
    setTab(item) {
        this.active = item.value;
    },
    getList() {
      // /photosqueryFilterAllPhotos
      axios({
        method: "get",
        url: `https://www.cf666.top/admin/photos/queryPhotosInfoDetails/${this.$route.query.id}`,
        params: {
          // id: this.$route.query.id
        },
      })
        .then((result) => {
          console.log(result);
          this.payload = result.data.payload;

          // result不是直接的返回结果
          // console.log("列表", result);
          // this.dataList = result.data.payload;
          // if (result.data.success) {
          //  this.$router.push({
          //     name:'home'
          //  })
          // }
          // result.data才是真正的返回结果
          // console.log("真正的数据：", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gopath() {
      this.$router.push({
        name: 'phoneIndex'
      })
    }
  },
};
</script>

<style lang='less' scoped>
.swiper-box {
  width: 100%;
  height: 11rem;
  img {
    width: 100%;
    // height: 11rem;
  }
}
.datails-center {
  width: 100%;
  padding: 0.8rem;
  border-top: 1px solid #e0e0e0;
  margin-top: 1rem;
  box-sizing: border-box;
  .datails-center-top {
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden; //隐藏溢出
    text-overflow: ellipsis; //省略号
    display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
    -webkit-line-clamp: 3; //限制行数
    -webkit-box-orient: vertical; //垂直方向排列
  }
  .datails-center-center {
    background: #f6f5f5;
    padding: 0.8rem;
    margin-top: 0.8rem;
    span {
      font-size: 0.8rem;
      color: #939497;
      margin-right: 1rem;
    }
    .money {
      color: #ff0026;
      font-size: 1.5rem;
    }
    .tag {
      span {
        background: #ff0026;
        color: #fff;
        border-radius: 4px;
        font-size: 0.8rem;
        padding: 0.3rem;
      }
    }
  }
  .datails-center-button {
    background: #ff0026;
    color: #fff;
    text-align: center;
    padding: 0.5rem 0;
    margin-top: 1rem;
  }
}
.dialog {
  width: 100%;
  height: 100vh;
  position: fixed;
  // position: relative;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
  .dialog-box {
    position: absolute;
    width: 70%;
    height: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    .dialog-box-content {
      height: 330px;
      line-height: 330px;
      text-align: center;
      border-bottom: 1px solid #7f7f7f;
      img {
        width: 100%;
        height: 330px;
      }
    }
    .queding {
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #40affe;
      font-size: 14px;
    }
  }
}

.tabstyle {
  display: inline-block;
  width: 50%;
  height: 50px;
  line-height: 50px;
  text-align: center;
//   padding: 10px;
  cursor: pointer;
//   box-sizing: border-box;
  border-top: 3px solid #e5ebe9;
  margin-top: 20px;
  background: #e0e0e0;
}
.active {
  border-top: 3px solid #ff0026;
  background: #fff;
}
.taglist-box {
  background: #fff;
  // padding: 30px;
  p {
    color: #7f7f7f;
    margin-bottom: 20px;
  }
  .details-title {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    background: #e5ebe9;
    color: #000;
    padding-left: 20px;
  }
  .img-box {
    img {
      width: 100%;
    }
  }
}
</style>