import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "swiper/css/swiper.css";
import axios from "axios";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
// import Swiper from 'swiper';
// Vue.use(Swiper);


Vue.config.productionTip = false;
// Vue.use(Swiper);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
